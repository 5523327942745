@charset "UTF-8";

@font-face {
  font-family: "platform-2021-07";
  src:url("fonts/platform-2021-07.eot");
  src:url("fonts/platform-2021-07.eot?#iefix") format("embedded-opentype"),
    url("fonts/platform-2021-07.woff") format("woff"),
    url("fonts/platform-2021-07.ttf") format("truetype"),
    url("fonts/platform-2021-07.svg#platform-2021-07") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "platform-2021-07" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dnaicon-"]:before,
[class*=" dnaicon-"]:before {
  font-family: "platform-2021-07" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dnaicon-world-outline:before {
  content: "\61";
}
.dnaicon-bell:before {
  content: "\62";
}
.dnaicon-bell-outline:before {
  content: "\63";
}
.dnaicon-plus:before {
  content: "\64";
}
.dnaicon-plus-circle:before {
  content: "\65";
}
.dnaicon-plus-circle-outline:before {
  content: "\66";
}
.dnaicon-search-thin:before {
  content: "\67";
}
.dnaicon-search-thick:before {
  content: "\68";
}
.dnaicon-search-minus:before {
  content: "\69";
}
.dnaicon-search-plus:before {
  content: "\6a";
}
.dnaicon-language:before {
  content: "\6b";
}
.dnaicon-arrow-left:before {
  content: "\6c";
}
.dnaicon-arrow-down:before {
  content: "\6d";
}
.dnaicon-arrow-right:before {
  content: "\6e";
}
.dnaicon-arrow-up:before {
  content: "\6f";
}
.dnaicon-chevron-down:before {
  content: "\70";
}
.dnaicon-chevron-left:before {
  content: "\71";
}
.dnaicon-chevron-right:before {
  content: "\72";
}
.dnaicon-chevron-up:before {
  content: "\73";
}
.dnaicon-chevron-down-thin:before {
  content: "\75";
}
.dnaicon-chevron-left-thin:before {
  content: "\76";
}
.dnaicon-chevron-right-thin:before {
  content: "\77";
}
.dnaicon-chevron-up-thin:before {
  content: "\74";
}
.dnaicon-arrow-down-thin:before {
  content: "\78";
}
.dnaicon-arrow-left-thin:before {
  content: "\79";
}
.dnaicon-arrow-right-thin:before {
  content: "\7a";
}
.dnaicon-arrow-up-thin:before {
  content: "\41";
}
.dnaicon-arrow-dropdown:before {
  content: "\42";
}
.dnaicon-arrow-dropleft:before {
  content: "\43";
}
.dnaicon-arrow-dropright:before {
  content: "\44";
}
.dnaicon-arrow-dropup:before {
  content: "\45";
}
.dnaicon-arrow-dropup-circle:before {
  content: "\46";
}
.dnaicon-arrow-dropright-circle:before {
  content: "\47";
}
.dnaicon-arrow-dropleft-circle:before {
  content: "\48";
}
.dnaicon-arrow-dropdown-circle:before {
  content: "\49";
}
.dnaicon-chevron-down-thick:before {
  content: "\4a";
}
.dnaicon-chevron-left-thick:before {
  content: "\4b";
}
.dnaicon-chevron-right-thick:before {
  content: "\4c";
}
.dnaicon-chevron-up-thick:before {
  content: "\4d";
}
.dnaicon-user-circle:before {
  content: "\4e";
}
.dnaicon-user-outline-1:before {
  content: "\4f";
}
.dnaicon-user-add:before {
  content: "\50";
}
.dnaicon-id-card:before {
  content: "\51";
}
.dnaicon-user-outline:before {
  content: "\52";
}
.dnaicon-users:before {
  content: "\53";
}
.dnaicon-users-outline:before {
  content: "\54";
}
.dnaicon-users-1:before {
  content: "\55";
}
.dnaicon-group-1:before {
  content: "\57";
}
.dnaicon-group-outline:before {
  content: "\58";
}
.dnaicon-group:before {
  content: "\59";
}
.dnaicon-user:before {
  content: "\56";
}
.dnaicon-star:before {
  content: "\5a";
}
.dnaicon-star-outline:before {
  content: "\30";
}
.dnaicon-bookmark:before {
  content: "\31";
}
.dnaicon-bookmark-outline:before {
  content: "\32";
}
.dnaicon-file-outline:before {
  content: "\33";
}
.dnaicon-file-add:before {
  content: "\34";
}
.dnaicon-files:before {
  content: "\35";
}
.dnaicon-file-settings:before {
  content: "\36";
}
.dnaicon-file-outline-1:before {
  content: "\37";
}
.dnaicon-file:before {
  content: "\38";
}
.dnaicon-home:before {
  content: "\39";
}
.dnaicon-home-outline:before {
  content: "\21";
}
.dnaicon-calendar:before {
  content: "\22";
}
.dnaicon-calendar-check:before {
  content: "\23";
}
.dnaicon-calendar-1:before {
  content: "\24";
}
.dnaicon-vertical-dots:before {
  content: "\25";
}
.dnaicon-list:before {
  content: "\26";
}
.dnaicon-burger-menu:before {
  content: "\27";
}
.dnaicon-grid-view-blocks:before {
  content: "\28";
}
.dnaicon-filter:before {
  content: "\29";
}
.dnaicon-delete-outline:before {
  content: "\2a";
}
.dnaicon-delete:before {
  content: "\2d";
}
.dnaicon-close-circle:before {
  content: "\2b";
}
.dnaicon-close-thin:before {
  content: "\2c";
}
.dnaicon-close-arrow-outline:before {
  content: "\2e";
}
.dnaicon-close-arrow:before {
  content: "\2f";
}
.dnaicon-close-circle-outline:before {
  content: "\3a";
}
.dnaicon-close:before {
  content: "\3b";
}
.dnaicon-minus:before {
  content: "\3c";
}
.dnaicon-minus-circle:before {
  content: "\3d";
}
.dnaicon-ban:before {
  content: "\3f";
}
.dnaicon-pencil:before {
  content: "\3e";
}
.dnaicon-clipboard-pencil-outline:before {
  content: "\40";
}
.dnaicon-bubble-outline:before {
  content: "\5b";
}
.dnaicon-chat-bubbles-circle:before {
  content: "\5d";
}
.dnaicon-chat-bubbles-box:before {
  content: "\5e";
}
.dnaicon-dashboard:before {
  content: "\5f";
}
.dnaicon-dashboard-outline:before {
  content: "\60";
}
.dnaicon-car:before {
  content: "\7b";
}
.dnaicon-car-1:before {
  content: "\7c";
}
.dnaicon-card-id:before {
  content: "\7d";
}
.dnaicon-card-atm:before {
  content: "\7e";
}
.dnaicon-car-2:before {
  content: "\5c";
}
.dnaicon-cart:before {
  content: "\e000";
}
.dnaicon-download-outline:before {
  content: "\e001";
}
.dnaicon-upload-outline:before {
  content: "\e002";
}
.dnaicon-location-outline:before {
  content: "\e003";
}
.dnaicon-location:before {
  content: "\e004";
}
.dnaicon-location-favorite:before {
  content: "\e005";
}
.dnaicon-pin:before {
  content: "\e006";
}
.dnaicon-new-window:before {
  content: "\e007";
}
.dnaicon-arrow-swap:before {
  content: "\e008";
}
.dnaicon-chevron-down-circle:before {
  content: "\e009";
}
.dnaicon-chevron-left-circle:before {
  content: "\e00a";
}
.dnaicon-chevron-right-circle:before {
  content: "\e00b";
}
.dnaicon-chevron-up-circle:before {
  content: "\e00c";
}
.dnaicon-shuffle:before {
  content: "\e00d";
}
.dnaicon-reload:before {
  content: "\e00e";
}
.dnaicon-chevron-double-right:before {
  content: "\e00f";
}
.dnaicon-chevron-double-left:before {
  content: "\e010";
}
.dnaicon-help:before {
  content: "\e011";
}
.dnaicon-help-outline:before {
  content: "\e012";
}
.dnaicon-information-outline:before {
  content: "\e013";
}
.dnaicon-information:before {
  content: "\e014";
}
.dnaicon-check:before {
  content: "\e015";
}
.dnaicon-check-circle:before {
  content: "\e016";
}
.dnaicon-list-1:before {
  content: "\e017";
}
.dnaicon-clipboard-pencil:before {
  content: "\e018";
}
.dnaicon-settings-outline:before {
  content: "\e019";
}
.dnaicon-settings:before {
  content: "\e01a";
}
.dnaicon-settings-1:before {
  content: "\e01b";
}
.dnaicon-options:before {
  content: "\e01c";
}
.dnaicon-image:before {
  content: "\e01e";
}
.dnaicon-login-1:before {
  content: "\e01f";
}
.dnaicon-login:before {
  content: "\e020";
}
.dnaicon-logout-1:before {
  content: "\e021";
}
.dnaicon-logout:before {
  content: "\e022";
}
.dnaicon-pin-1:before {
  content: "\e023";
}
.dnaicon-settings-2:before {
  content: "\e01d";
}
.dnaicon-share-square-o:before {
  content: "\e024";
}
.dnaicon-facebook:before {
  content: "\e025";
}
.dnaicon-facebook-outline:before {
  content: "\e026";
}
.dnaicon-facebook-rounded:before {
  content: "\e027";
}
.dnaicon-facebook-box:before {
  content: "\e028";
}
.dnaicon-instagram-outline:before {
  content: "\e029";
}
.dnaicon-instagram:before {
  content: "\e02a";
}
.dnaicon-instagram-box:before {
  content: "\e02b";
}
.dnaicon-twitter-rounded:before {
  content: "\e02c";
}
.dnaicon-twitter-bird:before {
  content: "\e02d";
}
.dnaicon-twitter-outline:before {
  content: "\e02e";
}
.dnaicon-twitter-2:before {
  content: "\e02f";
}
.dnaicon-linkedin-box:before {
  content: "\e032";
}
.dnaicon-linkedin-outline-box:before {
  content: "\e033";
}
.dnaicon-linkedin:before {
  content: "\e034";
}
.dnaicon-linkedin-rounded:before {
  content: "\e035";
}
.dnaicon-youtube-1:before {
  content: "\e038";
}
.dnaicon-youtube-outline:before {
  content: "\e039";
}
.dnaicon-youtube-2:before {
  content: "\e03a";
}
.dnaicon-social-whatsapp-1:before {
  content: "\e03b";
}
.dnaicon-whatsapp:before {
  content: "\e03c";
}
.dnaicon-whatsapp-outline:before {
  content: "\e03d";
}
.dnaicon-envelope-outline:before {
  content: "\e03e";
}
.dnaicon-envelope:before {
  content: "\e03f";
}
.dnaicon-airplane:before {
  content: "\e040";
}
.dnaicon-plane:before {
  content: "\e041";
}
.dnaicon-building-types:before {
  content: "\e042";
}
.dnaicon-building-outline:before {
  content: "\e043";
}
.dnaicon-building:before {
  content: "\e044";
}
.dnaicon-building-1:before {
  content: "\e045";
}
.dnaicon-folder-close-outline:before {
  content: "\e047";
}
.dnaicon-folder-closed:before {
  content: "\e046";
}
.dnaicon-folder-open-outline:before {
  content: "\e048";
}
.dnaicon-folder-open-solid:before {
  content: "\e049";
}
.dnaicon-money:before {
  content: "\e04a";
}
.dnaicon-money-1:before {
  content: "\e04b";
}
.dnaicon-dollar-sign:before {
  content: "\e04c";
}
.dnaicon-dollar-sign-outline:before {
  content: "\e04d";
}
.dnaicon-banknote:before {
  content: "\e04e";
}
.dnaicon-lock-outline:before {
  content: "\e050";
}
.dnaicon-lock:before {
  content: "\e051";
}
.dnaicon-success-outline:before {
  content: "\e030";
}
.dnaicon-success-filled:before {
  content: "\e031";
}
.dnaicon-success:before {
  content: "\e036";
}
.dnaicon-phone:before {
  content: "\e037";
}
.dnaicon-open-drawer:before {
  content: "\e052";
}
.dnaicon-open-drawer-1:before {
  content: "\e052";
}
.dnaicon-info:before {
  content: "\e04f";
}
.dnaicon-error:before {
  content: "\e053";
}
.dnaicon-error-outline:before {
  content: "\e054";
}
.dnaicon-jobs:before {
  content: "\e055";
}
.dnaicon-circle-filled:before {
  content: "\e056";
}
.dnaicon-circle-outline:before {
  content: "\e057";
}
.dnaicon-edit:before {
  content: "\e058";
}
.dnaicon-dot:before {
  content: "\e059";
}
.dnaicon-circle:before {
  content: "\e05a";
}
.dnaicon-vertical-square:before {
  content: "\e05b";
}
.dnaicon-spinner:before {
  content: "\e05c";
}
.dnaicon-warning-outline:before {
  content: "\e05d";
}
.dnaicon-warning:before {
  content: "\e05e";
}
.dnaicon-out:before {
  content: "\e05f";
}
.dnaicon-upload:before {
  content: "\e060";
}
.dnaicon-city:before {
  content: "\e061";
}
.dnaicon-policy-number:before {
  content: "\e062";
}
.dnaicon-product:before {
  content: "\e063";
}
.dnaicon-state:before {
  content: "\e064";
}
.dnaicon-tracking-number:before {
  content: "\e065";
}
.dnaicon-upc:before {
  content: "\e066";
}
.dnaicon-warranty-replacement-report:before {
  content: "\e067";
}
.dnaicon-zip-code:before {
  content: "\e068";
}
.dnaicon-device-category:before {
  content: "\e069";
}
.dnaicon-shipping:before {
  content: "\e06a";
}
.dnaicon-device-phone:before {
  content: "\e06b";
}
.dnaicon-country:before {
  content: "\e06c";
}
.dnaicon-street:before {
  content: "\e06d";
}
.dnaicon-checklist:before {
  content: "\e06e";
}
.dnaicon-check-clipboard:before {
  content: "\e06f";
}
