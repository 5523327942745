@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Text Standardization */
@layer components {
  /* Text Standardization classes */
  .text_base_secondary {
    @apply text-base text-secondary;
  }
  .text_base_secondary_bold {
    @apply text-base text-secondary font-bold;
  }

  .text_base_secondary-dark {
    @apply text-base text-secondary-dark;
  }
  .text_base_secondary-dark_bold {
    @apply text-base text-secondary-dark font-bold;
  }

  .text_base_primary {
    @apply text-base text-primary;
  }
  .text_base_primary_bold {
    @apply text-base text-primary font-bold;
  }

  .text_lg_secondary {
    @apply text-lg text-secondary;
  }
  .text_lg_secondary_bold {
    @apply text-lg text-secondary font-bold;
  }

  .text_lg_secondary-dark {
    @apply text-lg text-secondary-dark;
  }
  .text_lg_secondary-dark_bold {
    @apply text-lg text-secondary-dark font-bold;
  }

  /* RecordShell mainFields custom classes */
  .rs_mainfields_min-height {
    min-height: 7.5rem;
  }

  /* Dropdown width (Save and New/Continue) */
  .dropdown-panel-width{
    width: 12.55rem;
  } 

}

/* Scrollbar style */
::-webkit-scrollbar-track {
  @apply rounded bg-transparent;
}

::-webkit-scrollbar {
  @apply w-2 h-2 bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply rounded bg-gray-400;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.containerCls {
  height: calc(100vh - 4rem);
}

.tab-slot-container {
  height: calc(100% - 3rem);
}

/* React Tabs */
.react-tabs {
  @apply h-full;
}
.react-tabs__tab-list {
  /* sticky top-0 */
  @apply h-12 flex items-end bg-gray-100 z-20;
}

.react-tabs__tab {
  @apply h-10 items-center m-0 -ml-px gap-x-4;
}

.react-tabs__tab .close-tab-btn {
  @apply absolute -top-3 -right-1 hidden transition duration-150 z-10;
}

.react-tabs__tab .close-tab-btn i {
  @apply text-base text-red-500 z-10;
}

.react-tabs__tab:hover .close-tab-btn {
  @apply block;
}

.react-tabs__tab-panel {
  @apply bg-blue-50 p-r.25 overflow-auto;
}

.customTimePickerPopup {
  padding-top: 10px;
}
